<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="custom-rich-data-table">
    <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 overflow-auto ">
      <h3
        class="text-indigo-600 text-center text-xl dark:text-indigo-300"
        style="text-transform: uppercase; font-weight: bold"
      >
        {{ chart["name"] }}
      </h3>
      <div class="text-sm dark:text-surface-100">Click on each row to see details.</div>
      <DataTable
        :value="tableRows"
        tableStyle="min-width: 35rem;"
        :rows="5"
        ref="dt"
        v-model:filters="filters"
        filterDisplay="row"
        @rowSelect="onRowSelect"
        selectionMode="single"
        class="bg-indigo-200 dark:text-surface-100  text-base"
        :loading="loading"
        lazy
        paginator
        :totalRecords="totalRecords"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        sortMode="single"
        size="small">
        <template #header>
                    <div class="text-end">
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                    </div>
                </template>
        <template #empty>
            <span class="font-bold">No records found.</span>
        </template>
        <Column
          v-for="(column, colIdx) in columns"
          :key="colIdx"
          :field="column"
          :header="fnCapObj(column)"
          headerClass="text-lg"
          sortable
          :dataType="fnIsDateCol(column, this.filters) ? 'date' : 'text'"
        >
          <template #body="{ data }">            
            <span v-if="column in conf.colorBlockCols">
              <Tag :value="data[column]" :severity="getColorBlock(column, data[column])"  />
            </span>
            
            <span v-else-if="typeof data[column] === 'boolean'">
              <i
                class="pi"
                :class="{
                  'pi-check-circle text-green-500': data[column],
                  'pi-times-circle text-red-400': !data[column],
                }"
              ></i>
            </span>
            <span v-else>{{ fnParseData(data[column]) }}</span>
          </template>                    
          <template #filter="{ filterModel, filterCallback }">  
            <span v-if="fnIsDateCol(column, this.filters)">
              <Calendar v-model="filterModel.value" dateFormat="dd M yy" placeholder="dd mmm yyyy" mask="99 aaa 9999" @date-select="filterCallback" inputClass="w-[8rem] p-2" />     
            </span> 
            <span v-else>
              <InputText               
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder='search' 
              />          
            </span>                                                                                             
          </template>                  
        </Column>
      </DataTable>
    </div>

    <div>
      <PrimeDialog
        v-model:visible="showModel"
        maximizable
        modal
        header="View"
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >       
        <div v-if="!edit">
          <div v-if="showImageModel" class="mb-10">
                <img width="600" :src="imageUrl" alt="Picture.." />
            </div>             
        <table >
          <tbody>
            <tr v-for="(value, key) in selectedRow" :key="key" class="m-5 p-5 row"> 
              <template v-if="key !== 'image'">                    
              <td class="font-bold key w-48">{{ fnCapText(key) }}</td>              
              <td>:</td>
              <td class="m-2 p-3 block bg-violet-100 dark:text-slate-600">
                <pre v-if="key == 'message'">{{ value }}</pre>
                <div v-else v-html="fnParseValue(value)" />
              </td>  
              </template>          
          </tr>
          </tbody>
        </table>
      </div>        
        <table v-else>
            <tbody>
                <tr v-for="item in conf.updateCols"
                    :key="item.column"
                    class="m-5 p-5 row">
                    <td class="font-bold key w-48">{{ fnCapText(item.column) }}</td>
                    <td>:</td>
                    <td class="m-2 value w-full">
                        <InputText v-if="item.formInput == 'text'"
                                   v-model="editedRow[item.column]"
                                   class="bg-indigo-100 w-full edit-input dark:text-slate-600" />

                        <Dropdown v-if="item.formInput == 'dropdown'"
                                  v-model="editedRow[item.column]" :options="item.valueList" optionLabel="text" optionValue="value"
                                  placeholder="Please Select one" class="w-full" inputClass="edit-input bg-indigo-100 dark:text-slate-600" />

                        <InputNumber v-if="item.formInput == 'number'"
                                     v-model="editedRow[item.column]" inputClass="w-full edit-input bg-indigo-100 dark:text-slate-600" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-end mt-4">
          <div v-if="!edit" @click="editDoc" class="cursor-pointer">
            <span>
              <i class="pi pi-file-edit" style="color: slateblue"></i>
            </span>
          </div>
          <div>
              <PrimeButton v-if="edit"
                           @click="updateCancel"
                           label="Cancel"
                           severity="secondary"
                           icon="pi pi-arrow-circle-left" iconPos="left" 
                           class="mr-2" />
              <PrimeButton v-if="edit"
                           @click="updateDoc"
                           label="Update" title="Save changes."
                           icon="pi pi-check-circle" iconPos="right" />
              
          </div>
        </div>
      </PrimeDialog>
    </div>
  </div>
</template>

<script>
import Tag from "primevue/tag";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import PrimeDialog from "primevue/dialog";
import PrimeButton from "primevue/button";
import axios from "axios";
import Button from "primevue/button";
import Calendar from 'primevue/calendar';

import { FilterMatchMode } from "primevue/api";
import { ref } from "vue";

import { parseJsonValue } from "@/utils/ParseJson.js";
import { 
  capitalizeFirstLetter, 
  arrayHasValue, 
  valueIsTrue, 
  tryParseFloat,  
  processColumnObject,
  convertBase64ToImage,
  formatDateTime,
  updateRecords,
  createCSV,
  downloadCSV,
  dateTimeToString,
  isDateColumn,
  isFilterColumn
} from "@/utils/utils.js";

export default {
  components: {
    DataTable, Column,
    InputText, Dropdown, InputNumber,
    PrimeDialog,
    PrimeButton,
    Button,
    Tag,
    Calendar
  },
  props: {
    chartObject: Object,
    url: Object,
    clickEvent: Object
  },

  data() {
    return {
      dt: ref(),
      SelectedDateData:{},
      queryFilters:{},
      columns: [],
      title: "",
      tableRows: [],
      imageUrl: "",
      filters: {},
      sorting: undefined,
      showModel: false,
      showImageModel: false,
      selectedRow: {},
      edit: false,
      editedRow: {},
      base64String: "",
      rowSchema: {},
      first: 0,
      totalRecords: 0,
      loading: false,
      schema: {},
      chart: {},
      conf: {},
    };
  },
    watch: {
        chartObject: {
            immediate: true,
            handler(newValue) {                
                this.chart = newValue;
                console.log("_-------_ URL");                                                
                this.loadData(
                    {
                        first: 0,
                        rows: 5,
                    },
                    newValue,
                    {}
                );
            }
        },
        clickEvent: function (val) {
            console.log("CustomRichDataTableComponent - clickEvent", val, this.filters);
            if (val != undefined && val.type == "filtering") {
                var keys = Object.keys(this.filters);
                if (keys.length > 0) {
                    keys.forEach((key) => {
                        this.filters[key].value = undefined;
                    });
                }
                this.filters[val.key] = { value: val.value, matchMode: FilterMatchMode.EQUALS };                
                this.loadData(
                    this.lazyParams,
                    this.chart,
                    this.filters
                );
            }
        }
    },
  methods: { 
    
    exportCSV  () {      
      
      this.loading = true           
      axios
        .get(`${this.$apiBase}/${this.url.onLoad}/exportData`,  {
          params: this.queryFilters,
        })
        .then((response) => {

          const additionalData = response.data.logs                    
          
          const csv = createCSV(additionalData);                                                  
          downloadCSV(csv, `${this.chart["name"]}.csv`);
          this.loading = false
         
        })
        .catch((error) => {
          console.error("Error loading data:", error);
        });        
  },      
    
    onRowSelect(event) {     
      this.selectedRow = event.data;
      this.editedRow = event.data;
      this.edit = false;
      let getUrl = `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${this.selectedRow._id}`      
      axios
        .get(getUrl)
        .then((response) => {
          console.log("----- ROW -----");
          let doc = updateRecords([response.data.document], response.data.schema);
          this.selectedRow = doc[0];                 
          if ("image" in this.selectedRow) {
            this.base64String = this.selectedRow.image;            
            this.imageUrl = convertBase64ToImage(this.selectedRow.image);            
            this.showImageModel = true;            
          }          
          this.editedRow = doc[0];
          this.rowSchema = response.data.schema;
          this.showModel = true;
          //return {"schema": schema, "document": document}
        })
        .catch((error) => {
          console.error("Error loading data:", error);
        });
    },
      async updateDoc() {        
          try {
              for (let key in this.editedRow) {
                  this.selectedRow[key] = this.editedRow[key];
              }

              for (let key in this.rowSchema) {
          
                  if (
                      this.rowSchema[key] === "number" ||
                      this.rowSchema[key] === "float" ||
                      this.rowSchema[key] === "int"
                  ) {
                      this.selectedRow[key] = tryParseFloat(this.selectedRow[key]);
                  } else if (this.rowSchema[key] === "bool") {
                      
                      this.selectedRow[key] = valueIsTrue(this.selectedRow[key]);
                  }
                  
                  else if (this.rowSchema[key] === "str"){                    
                      this.selectedRow[key] = this.selectedRow[key].trim();
                  }
              }
          } catch (error) {
              console.log(error);
              return;
          }                         
          try {
              const response = await axios.put(
                  `${this.$apiBase}/${this.url.onUpdate}/${this.selectedRow._id}`,
                  { document: this.selectedRow, schema: this.rowSchema, config: this.conf }
              );
              console.log(response);
              this.edit = false;
              // this.selectedRow = this.editedRow;
              this.showModel = false;
              this.loadData(
                  this.lazyParams,
                  this.chart,
                  this.filters
              );
              this.$emit("recordUpdated")
          } catch (error) {
              console.error("Error:", error);
          }
      },
    editDoc() {
      this.edit = true;      
      this.editedRow = {};
     
      this.conf.updateCols.forEach((col) => {
          this.editedRow[col.column] = this.selectedRow[col.column];
      });
    },
    updateCancel(){
        this.edit = false;
        this.editedRow = {};
    },
    onPage(event) {
        console.log("+++++ PAGING EVENT TRIGGERED +++++");        
        this.lazyParams = event;
        this.loadLazyData(event);
    },
    onFilter(event) {                        
        console.log("+++++ FILTERING EVENT TRIGGERED +++++");                      
        this.lazyParams = event;                        
        this.loadLazyData(event);
    },
    onSort(event) {
        console.log("+++++ SORTING EVENT TRIGGERED +++++");        
        this.lazyParams = event;
      this.loadLazyData(event);
    },
    loadLazyData(event) {                        
      this.lazyParams = {
        ...this.lazyParams,
        first: event?.first || this.first,
      };
      console.log("++++++++++ LOAD LAZY DATA ++++++++++++");      
      this.loadData(event, this.chart, event.filters);
      this.queryFilters = this.loadFilters(event, event.filters)
    },
    loadFilters(event, filters) {            
      
      const currentPage = event && event.page != undefined ? event.page + 1 : 1;
      const pageSize = event != undefined && event.rows != undefined ? event.rows : 10;

      const formattedFilters = { ...filters };

      Object.keys(formattedFilters).forEach((key) => {
        if (formattedFilters[key]?.value instanceof Date) {
          formattedFilters[key].value = dateTimeToString(formattedFilters[key].value )            
        }
    });

      const queryParams = {
        page: currentPage,
        page_size: pageSize,
        sort_field: event ? event.sortField : "_id",
        sort_order: event ? event.sortOrder : 1,
        filters: JSON.stringify(filters),
      };                  
      return queryParams

    },
    loadData( event, chart, filters) {
      this.loading = true;
      

      console.log("-------- URL");                  
      axios
        .get(`${this.$apiBase}/${this.url.onLoad}`, {
          params: this.loadFilters(event,filters),
        })
        .then((response) => {                                                                        
          let records = response.data.logs;
          this.totalRecords = response.data.total_documents;
          this.schema = response.data.schema;
          this.conf = response.data.conf;
          this.chart = chart;    
          let filterDate = response.data.filters;                

          this.tableRows = updateRecords(records, this.schema);                                
          
          if (!this.conf.ratingCols) {
            this.conf.ratingCols = {};
          }

          if (!this.conf.colorBlockCols) {
            this.conf.colorBlockCols = {};
          }
                    
          if (arrayHasValue(records))  {                                                                                 
            this.columns = Object.keys(records[0]);
            this.columns = this.columns.filter((item) => item !== "_id");                        
            
            
            Object.keys(records[0]).forEach((key) => {                                                      
                                                          
            const filterValue = response.data.filters[key]?.value || null;                          
            if(isFilterColumn(key, this.schema)) 
            {                            
              this.filters[key] = {
                value: filterValue ? new Date(filterValue) : null,
                matchMode: response.data.filters[key]?.matchMode || FilterMatchMode.DATE_IS,
              }; 
            } 
            else{
              this.filters[key] = {
                value: filterValue || null,
                matchMode: response.data.filters[key]?.matchMode || FilterMatchMode.CONTAINS,
              }; 
            }                                                                                                                  
            });                 
          }      
          else{            
            Object.keys(filterDate).forEach((key) => {             
              if(isDateColumn(key,this.filters))
            {                                         
              this.filters[key] = {
                value: response.data.filters[key]?.value ? new Date(response.data.filters[key]?.value) : null,
                matchMode: response.data.filters[key]?.matchMode || FilterMatchMode.DATE_IS,
              }; 
              
            }
              
            })                                  
          }                                                                                               
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error loading data:", error);
          this.logDataLoading = false;
        });
    },
      mapOnSelectTable(val,mapping) {
        return Object.keys(val).reduce((mappedRow, key) => {                                                                                                                                                               
                        const newKey = mapping[key] || key;                        
                        mappedRow[newKey] = val[key];                                    
                        return mappedRow;
                    }, {})
      },      
    getColorBlock(col, val) {                                
      return this.conf.colorBlockCols[col] && this.conf.colorBlockCols[col][val]
        ? this.conf.colorBlockCols[col][val]
        : this.conf.colorBlockCols[col]["default-color"];
    },
    getTotalRatingStars(col) {
      return this.conf.ratingCols[col]["totalStars"]
        ? this.conf.ratingCols[col]["totalStars"]
        : 5;
    },
    getRating(value) {
      return ref(value);
      },
      fnParseValue(text) {                        
          return parseJsonValue(text);
      },
      fnCapText(text) {
          return capitalizeFirstLetter(text);
      },
      fnCapObj(obj) {
                return processColumnObject(obj);
    }, 
    fnParseData(data) {                  
      if (Object.prototype.toString.call(data) === '[object Date]') {        
        //return dateTimeToString(data, " ");
        return formatDateTime(data, " ");
        
      }
      return data;
    },
    fnIsDateCol(col,filter,schema){
      return isDateColumn(col,filter,schema)

    }
  },
};
</script>

<style scoped>
.star {
  color: gray; /* Adjust star color as needed */
  font-size: 24px; /* Adjust star size as needed */
}

.filled {
  color: green; /* Adjust filled star color as needed */
}
</style>
