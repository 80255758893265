<template>
    <div class="custom-rich-data-table">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 h-[500px] overflow-auto">
            <h3 class="text-indigo-600 text-center text-xl my-1"
                style="text-transform: uppercase; font-weight: bold">
                {{ chart["name"] }}
            </h3>
            <div class="text-sm dark:text-surface-200">Click on each row to see details.</div>            
            <DataTable stripedRows
                       :value="tableRows"
                       ref="dt"
                       tableStyle="min-width: 35rem;"
                       paginator
                       :rows="5"
                       :loading="loading"
                       @rowSelect="onRowSelect"                       
                       selectionMode="single"
                       class="text-base"                       
                       size="small"
                       >
                <template #header>
                    <div class="text-end">
                        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                    </div>
                </template>
                <template #empty>
                    <span class="font-bold">No records found.</span>
                </template>                
                <Column v-for="(column, colIdx) in columns"
                        bodyClass="dark:text-surface-200"
                        :key="colIdx"
                        :field="column"
                        :header="fnCapObj(column)"
                        headerClass="text-lg">
                    <template #body="{ data }">
                        <span v-if="conf.colorBlockCols != undefined && column in conf.colorBlockCols">
                            <Tag :value="data[column]" :severity="getColorBlock(column, data[column])" />
                        </span>
                        <span v-else-if="conf.ratingCols != undefined && column in conf.ratingCols">
                            <span v-for="starIndex in getTotalRatingStars(column)"
                                  :key="starIndex"
                                  class="star"
                                  :class="{ filled: starIndex <= Math.round(data[column]) }">
                                {{ starIndex <= Math.round(data[column]) ? "&#9733;" : "&#9734;" }}
                            </span>
                        </span>
                        <span v-else-if="typeof data[column] === 'boolean'">
                            <i class="pi"
                               :class="{ 'pi-check-circle text-green-500': data[column], 'pi-times-circle text-red-400': !data[column] }"></i>
                        </span>
                        <span v-else>{{ fnParseData(data[column]) }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div>
            <details-table-component :selectedRow="selectedRow" :showModel="showModel"
                                    :imageUrl="imageUrl" :showImageModel="showImageModel" 
                                    :updateCols="conf.updateCols" :enableEdit="conf.enableEdit"
                                    :rowSchema="rowSchema" :tableSource="tableSource"
                                    @onClose="fnDetailsClose" @updateRow="fnUpdateDoc" />
        </div>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Tag from "primevue/tag";
    import Button from "primevue/button";
    
    import DetailsTableComponent from "@/components/Tables/DetailsTableComponent.vue";

    import axios from "axios";
    import { ref } from "vue";    

    import { parseJsonValue } from "@/utils/ParseJson.js";
    import {
        capitalizeFirstLetter,
        valueIsTrue,
        tryParseFloat,
        convertBase64ToImage,
        processColumnObject,
    formatDateTime,
        updateRecords,
        createCSV,
        downloadCSV
    } from "@/utils/utils.js";
     
    export default {
        components: {
            DataTable, Column, Tag,
            DetailsTableComponent,
            Button
        },
        emits: ["recordUpdated"],
        props: {
            chartObject: Object,
            url: Object,
        },
        data() {
            return {
                dt: ref(),
                columns: [],
                title: "",
                tableRows: [],
                chart: {},
                loading: false,
                showModel: false,
                showImageModel: false,
                selectedRow: {},
                base64String: "",
                imageUrl: "",
                schema: {},
                rowSchema: {},
                conf: {},
                tableSource: ""
            };
        },
        watch: {
            chartObject: {
                immediate: true,
                handler(newValue) {
                    this.chart = newValue;
                    this.loadData();
                },
            },
        },
        methods: {
            exportCSV  () {
          this.loading = true        
          const additionalData = this.tableRows
          const csv = createCSV(additionalData);          
          downloadCSV(csv, `${this.chart["name"]}.csv`);
          this.loading = false                              
        },                              
            onRowSelect(event) {                
              var getUrl = `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${event.data[this.conf.idCol]}`;                                              
              
                axios
                    .get(getUrl)
                    .then((response) => {                        
                        console.log("----- ROW -----");                        
                        let doc = response.data.data;
                        doc[this.tableSource] = updateRecords([doc[this.tableSource]], response.data.schema)[0];
                        this.selectedRow = doc;
                        this.rowSchema = response.data.schema;

                        //this.selectedRow = response.data.data;
                        if ("image" in this.selectedRow) {
                            this.base64String = this.selectedRow.image;
                            //this.convertBase64ToImage();
                            this.imageUrl = convertBase64ToImage(this.selectedRow.image);                            
                            this.showImageModel = true;
                            delete this.selectedRow.image;
                        }

                        if (Object.keys(this.selectedRow).length > 0) {
                            this.showModel = true;
                        }                      
                    })
                    .catch((error) => {
                        console.error("Error loading data:", error);
                    });
            },
            async loadData() {                                                                
                this.loading = true;                                                                                                       
                axios
                    .get(`${this.$apiBase}/${this.url.onLoad}`)
                    .then((response) => {                                                                                                                                                                                                 
                        const data = response.data;
                        this.columns = data.cols;
                        //this.tableRows = data.data;
                        this.schema = data.schema;
                        this.conf = data.conf;   

                        this.tableRows = updateRecords(data.data, data.schema);

                        if (this.conf.collection != undefined)
                        {
                          this.tableSource = this.conf.collection;
                        }
                        else if (this.conf.type != undefined)
                        {
                            this.tableSource = this.conf.type;
                        }                                                                       
                        
                        this.loading = false;                                                
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                        return [];
                    });
            },           
            fnDetailsClose() {                
                this.showModel = false;
                this.showImageModel= false;
            },
            fnUpdateDoc(editedRow) {                                                
                let saveRow = this.selectedRow[this.tableSource];
                let rowSchema = this.rowSchema;
                
                try {
                    console.log("Updating row with edited values...");
                    for (let key in editedRow) {                        
                        saveRow[key] = editedRow[key];
                    }
                    console.log("Converting values to datatypes from row schema.");
                    
                    for (let key in rowSchema) {                        
                        if (["number", "float", "int"].includes(rowSchema[key])) 
                        {
                            saveRow[key] = tryParseFloat(saveRow[key]);
                        } else if (rowSchema[key] === "bool") {                            
                            saveRow[key] = valueIsTrue(saveRow[key]);
                        }
                        //else if (this.rowSchema[key] === 'datetime') {
                            //console.log(this.selectedRow[key]);
                            //this.selectedRow[key] = new Date(this.selectedRow[key]);
                            //console.log(this.selectedRow[key]);
                        //}
                        else if (rowSchema[key] === "str"){                                                      
                            saveRow[key] = saveRow[key].trim();
                        }
                        // else if (this.schema[key] === 'object') {
                        //   console.log(JSON.parse(this.editedRow[key]))
                        //   this.editedRow[key] = JSON.parse(this.editedRow[key])
                        // }
                    }
                }
                catch (error) {
                    console.log(error);
                    return;
                }                
                if (this.showImageModel) {
                    this.selectedRow.image = this.base64String;
                }
                
                let putUrl = `${this.$apiBase}/${this.url.onUpdate}/${saveRow[this.conf.idCol]}`;
                let updateContent = { document: saveRow, schema: rowSchema, config: this.conf  };                                
                axios.put(putUrl, updateContent).then(
                    () => {                        
                        // this.edit = false;
                        // this.selectedRow = this.editedRow;
                        this.showModel = false;
                        this.$emit("recordUpdated");
                        this.loadData();
                    }
                ).catch((error) => {
                    console.error("Error:", error);
                    //this.$emit('onError', 'Unexpected Error', 'An error occurred when trying to Add the Asset!!!', ToastSeverity.ERROR);
                });                    
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnParseValue(text) {
                return parseJsonValue(text);
            },  
            fnCapObj(obj) {
                return processColumnObject(obj);
            },            
            getColorBlock(col, val) {
                return this.conf.colorBlockCols[col] && this.conf.colorBlockCols[col][val]
                    ? this.conf.colorBlockCols[col][val]
                    : this.conf.colorBlockCols[col]["default-color"];
            },
            getTotalRatingStars(col) {
                return this.conf.ratingCols[col]["totalStars"]
                    ? this.conf.ratingCols[col]["totalStars"]
                    : 5;
            },
            getRating(value) {
                return ref(value);
            },
            fnParseData(data) {
              if (Object.prototype.toString.call(data) === '[object Date]') {
                data = formatDateTime(data, " ");
              }
              return data;
            }
        }
  };
</script>
