<template>
  <div class="custom-rich-data-table">
    <div
      class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400"
    >
      <h3
        class="text-indigo-600 text-center text-xl dark:text-indigo-300"
        style="text-transform: uppercase; font-weight: bold"
      >
        {{ chart["name"] }}
      </h3>
      <div class="text-sm dark:text-surface-200">Click on each row to see details.</div>
      <DataTable
        stripedRows
        :value="tableRows"
        ref="dt"
        tableStyle="min-width: 35rem;"
        paginator
        :rows="5"
        :loading="loading"
        @rowSelect="onRowSelect"
        selectionMode="single"
        style="width: 100%"
        class="text-base"
        size="small"
      >
      <template #header>
          <div class="text-end">
              <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
          </div>
      </template>
        <Column
          v-for="(column, colIdx) in columns"
           bodyClass="dark:text-surface-200"
          :key="colIdx"
          :field="column"
          :header="fnCapObj(column)"
           headerClass="text-lg">
        </Column>
      </DataTable>
    </div>
    <div>
        <details-table-component :selectedRow="selectedRow" :showModel="showModel"
                                 :imageUrl="imageUrl" :showImageModel="showImageModel"
                                 :updateCols="conf.updateCols" :enableEdit="conf.enableEdit"
                                 :tableSource="tableSource" :rowSchema="schema"
                                 @onClose="fnDetailsClose" @updateRow="fnUpdateDoc"/>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import Button from "primevue/button";
import { ref } from "vue"; 
//import PrimeDialog from "primevue/dialog";
import DetailsTableComponent from "@/components/Tables/DetailsTableComponent.vue";
import {        

        processColumnObject,
        tryParseFloat,
        valueIsTrue,
        createCSV,
        downloadCSV
    } from "@/utils/utils.js";

export default {
  components: {
    DataTable,
    Column,
    //PrimeDialog,
    DetailsTableComponent,
    Button
  },
  props: {
    chartObject: Object,
    url: Object,
  },
  data() {
    return {
      dt: ref(),
      columns: [],
      title: "",
      tableRows: [],
      chart: {},
      loading: false,
      showModel: false,
      selectedRow: {},
      base64String: "",
      imageUrl: "",
      showImageModel: "",
      schema: {},
      conf: {},
      tableSource: ""
    };
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {        
        this.chart = newValue;
        this.loadData();
      },
    },
  },
  methods: {
    exportCSV  () {
          this.loading = true        
          const additionalData = this.tableRows
          const csv = createCSV(additionalData);
          downloadCSV(csv, `${this.chart["name"]}.csv`);
          this.loading = false                                
        },                   
    onRowSelect(event) {   
      let plainObject = { ...event.data }; 
      let jsonString = JSON.stringify(plainObject); 
      axios
        .get(
          `${this.$apiBase}/${this.url.onRowSelect}/${this.chart.configuration}/${event.data._id}/${jsonString}`
        )
        .then((response) => {                              
          this.selectedRow = response.data.data;                    
          
          if ("image" in this.selectedRow) {
            this.base64String = this.selectedRow.image;
            this.convertBase64ToImage();
            this.showImageModel = true;
          } else if (Object.keys(this.selectedRow).length > 0) {
            this.showModel = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return [];
        });
    },
    async loadData() {
      this.loading = true;
      console.log("--------- LOAD DATA");                  
      axios
        .get(`${this.$apiBase}/${this.url.onLoad}`)
        .then((response) => {
          console.log("-- NEW TABLE");          
          this.tableRows = response.data.data;
          this.columns = response.data.cols;
          
          this.schema = response.data.schema;
          this.conf = response.data.conf;
          
          if (this.conf.collection != undefined){
            this.tableSource = this.conf.collection;
          }
          else if (this.conf.type != undefined)
          {
              this.tableSource = this.conf.type;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          return [];
        });
    },

    fnUpdateDoc(editedRow) {                                                
                let saveRow = this.selectedRow[this.tableSource];
                let rowSchema = this.schema;                
                let plainObject = { ...saveRow }; 
                let jsonString = JSON.stringify(plainObject);                                                
                
                
                try {
                    console.log("Updating row with edited values...");
                    for (let key in editedRow) {                        
                        saveRow[key] = editedRow[key];
                    }
                    console.log("Converting values to datatypes from row schema.");
                    
                    for (let key in rowSchema) {
                        //console.log("rowSchema Key:",key);
                        if (["number", "float", "int"].includes(rowSchema[key])) 
                        {
                            saveRow[key] = tryParseFloat(saveRow[key]);
                        } else if (rowSchema[key] === "bool") {
                            //console.log(this.selectedRow[key] === "true");
                            saveRow[key] = valueIsTrue(saveRow[key]);
                        }                       
                        else if (rowSchema[key] === "str"){                                                      
                            saveRow[key] = saveRow[key].trim();
                        }                       
                    }
                }
                catch (error) {
                    console.log(error);
                    return;
                }                
                if (this.showImageModel) {
                    this.selectedRow.image = this.base64String;
                }
                
                let putUrl = `${this.$apiBase}/${this.url.onUpdate}/${saveRow[this.conf.idCol]}`;
                let updateContent = { document: saveRow, schema: rowSchema, config: this.conf, oldJob:jsonString  };                                                                
                                
                axios.put(putUrl, updateContent).then(
                    () => {                        
                        // this.edit = false;
                        // this.selectedRow = this.editedRow;
                        this.showModel = false;
                        this.$emit("recordUpdated");
                        this.loadData();
                    }
                ).catch((error) => {
                    console.error("Error:", error);
                    //this.$emit('onError', 'Unexpected Error', 'An error occurred when trying to Add the Asset!!!', ToastSeverity.ERROR);
                });                    
            },

    convertBase64ToImage() {
      const dataURIprefix = "data:image/";
      let dataURI = this.base64String;

      if (!this.base64String.startsWith(dataURIprefix)) {
        const mimeType = "image/png"; // Change to 'image/jpeg' or other type if needed
        dataURI = `${dataURIprefix}${mimeType};base64,${this.base64String}`;
      }

      this.imageUrl = dataURI;
    },
    fnCapObj(obj) {
                return processColumnObject(obj);
            },   
    fnDetailsClose() {
      this.showModel = false;
      this.showImageModel = false;
    },
  },
};
</script>

<style scoped></style>
